<template>
  <span>
      <h1 class="headline blue--text text-center mt-4 mb-3">SMS-laskutuskanavaraportti</h1>
      <v-divider></v-divider>
      Valitse päivämäärä:
      <v-row>
            <v-col cols="2">
                <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
            </v-col>
            <v-col cols="2">
                <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-btn
                        class="v-btn ma-3 green float-right"
                        @click="getResource"
                        small
                >
                    HAE
                </v-btn>
            </v-col>
        </v-row>
      <v-select
              v-model="selectedCategories"
              :items="categories"
              :return-object="false"
              item-value="id"
              label="Valitse laskutuskanava(t)"
              multiple
              ref="Vueselect"
              style="max-width: 600px"
      >
    </v-select>

      <v-row v-if="Object.keys(resource).length > 0" style="max-width: 1100px">
          <v-col v-for="btn in resourceSelectors" :key="btn.id" class="text-center">
            <v-btn
                    small
                @click="selectedResourceSelector = btn.id"
              >
                {{ btn.name }}
            </v-btn>
          </v-col>
      </v-row>

      <v-simple-table
              class="mb-12"
              v-if="Object.keys(resource).length > 0"
      >
          <thead>
          <tr>
              <th>
                  &nbsp;
              </th>
              <th
                colspan="3"
                class="blue-grey darken-3 text-center font-weight-bold"
              >
                Kuluttajahinnat alv 25.5% €
              </th>
              <th
                      colspan="3"
                      class="grey darken-1 text-center font-weight-bold"
              >
                Tilitykset alv 0% €
              </th>
              <th
                      colspan="4"
                      class="blue-grey darken-3 text-center font-weight-bold"
              >
                Muuttuvat kustannukset €
              </th>
              <th>
                  &nbsp;
              </th>
              <th
                  colspan="2"
              >
                Viestimäärät (kpl)
              </th>
          </tr>
          <tr>
              <th>
                  Selite
              </th>
              <th class="blue-grey darken-3">Yht.</th>
              <th class="blue-grey darken-3">Operaattori</th>
              <th class="blue-grey darken-3">Credio</th>
              <th class="grey darken-1 text-center font-weight-bold">Yht.</th>
              <th class="grey darken-1 text-center font-weight-bold">Operaattori</th>
              <th class="grey darken-1 text-center font-weight-bold">Credio</th>
              <th class="blue-grey darken-3">Palkat</th>
              <th class="blue-grey darken-3">Suora</th>
              <th class="blue-grey darken-3">Mainonta</th>
              <th class="blue-grey darken-3">Viestit</th>
              <th>
                  Myyntikate €
              </th>
              <th>Saapuneet</th>
              <th>Laskutetut</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="tr in filteredResource">
            <tr :key="tr.title">
                <td>
                    {{ tr.title }}
                    <v-icon v-if="breakdown_visibility != tr.title" @click="breakdown_visibility = tr.title">mdi-arrow-down</v-icon>
                    <v-icon v-else @click="breakdown_visibility = null">mdi-arrow-up</v-icon>
                </td>
                <td class="blue-grey darken-3">{{ tr.eu_price }}</td>
                <td class="blue-grey darken-3">{{ tr.eu_price_operator }}</td>
                <td class="blue-grey darken-3">{{ tr.eu_price_credio }} <!-- percentage? --></td>
                <td class="grey darken-1">{{ tr.accounting }}</td>
                <td class="grey darken-1">{{ tr.accounting_operator }}</td>
                <td class="grey darken-1">{{ tr.accounting_credio }} <!-- percentage? --></td>
                <td class="blue-grey darken-3">{{ tr.variable_cost_salary }}</td>
                <td class="blue-grey darken-3">{{ tr.variable_cost_advertise }}</td>
                <td class="blue-grey darken-3">{{ tr.variable_cost_advertise2 }}</td>
                <td class="blue-grey darken-3">{{ tr.variable_cost_messages }}</td>
                <td>{{ tr.sales_margin }}</td>
                <td>{{ tr.received_messages_count }}</td>
                <td>{{ tr.charged_message_count }}</td>
            </tr>
            <template v-if="breakdown_visibility == tr.title">
              <template v-for="tr2 in tr.charging_channels_data">
                <tr :key="tr2.name" style="color: darkgrey">
                    <td>{{ tr2.name }}</td>
                    <td class="blue-grey darken-3">{{ tr2.eu_price }}</td>
                    <td class="blue-grey darken-3">{{ tr2.eu_price_operator }}</td>
                    <td class="blue-grey darken-3">{{ tr2.eu_price_credio }} <!-- percentage? --></td>
                    <td class="grey darken-1">{{ tr2.accounting }}</td>
                    <td class="grey darken-1">{{ tr2.accounting_operator }}</td>
                    <td class="grey darken-1">{{ tr2.accounting_credio }} <!-- percentage? --></td>
                    <td class="blue-grey darken-3">{{ tr2.variable_cost_salary }}</td>
                    <td class="blue-grey darken-3">-</td>
                    <td class="blue-grey darken-3">-</td>
                    <td class="blue-grey darken-3">{{ tr2.variable_cost_messages }}</td>
                    <td>{{ tr2.sales_margin }}</td>
                    <td>{{ tr2.received_message_count }}</td>
                    <td>{{ tr2.charged_message_count }}</td>
                </tr>
              </template>
            </template>
          </template>
          </tbody>
      </v-simple-table>
  </span>
</template>

<script>
export default {
    name: "ChargingChannelReport",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            categories: [],
            resource: [],
            selectedCategories: [],
            timeout: null,
            breakdown_visibility: null,
            resourceSelectors: [
                {id: 'charging_channels_daily', name: 'Päiväkohtainen'},
                {id: 'charging_channels_weekly', name: 'Viikko'},
                {id: 'charging_channels_monthly', name: 'Kuukausittainen'},
                {id: 'charging_channels_total', name: 'Yhteensä'},
                {id: 'charging_channels_daily_ruled_by_channel', name: 'Kanavittain/Päivä'},
                {id: 'charging_channels_ruled_by_service', name: 'Palvelu'},
                {id: 'charging_channels_ruled_by_service_type', name: 'Palvelutyyppi'},
            ],
            selectedResourceSelector: 'charging_channels_daily',

        }
    },
    watch: {
        selectedCategories(){

            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                if (this.selectedCategories.length > 0){
                    this.$refs.Vueselect.blur();
                    if(this.selectedCategories.length > 0) this.getResource()
                }
            }, 2000);
        }
    },
    computed: {

        filteredResource(){

            if(this.resource) return this.resource[this.selectedResourceSelector]

            return []

        }

    },
    methods:{
        async getResources(){
            await this.axios.get('reports/charging_channels_names').then((resources) => {
                this.categories = resources.data
            }).catch ((error) => {
                console.error(error);
            });
        },

        async getResource(){
            this.resourceLoading = true
            this.resource = []
            await this.axios.post('reports/charging_channels_report', {
                from: this.startDate,
                to: this.endDate,
                channels: this.selectedCategories
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
    },
    mounted(){
        this.startDate = this.$day().subtract(7, 'day').format("DD.MM.YYYY")
        this.endDate = this.$day().format("DD.MM.YYYY")
        this.getResources()
    }

}
</script>

<style scoped>

</style>
<script setup>
</script>